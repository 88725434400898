<template>
    <div class="home">  
      <div class="recipes">
        <div class="card" v-for="recipe in $store.state.recipes" :key="recipe.slug">
          <h2>{{ recipe.title}}</h2>
          <p>{{ recipe.description }}</p>
          <img id="naslovna" :src="recipe.image"/>
          <router-link :to="`/recipe/${recipe.slug}`">
            <button id="recept">^itaj Daqe</button>
          </router-link>
     
        </div>
      </div>
  </div>
  
  </template>
  
  <script>
  import { ref } from 'vue';
  
  
  export default {
    name: 'HomeI',
    setup () {
      const newRecipe = ref({
        title: '',
        description: '',
        image:'',
        imagea: '',
        imageb: '',
        imagec: '',
        imaged: '',
        imagee: '',
        a:'',
        txt:'',
        ingredients: [],
        method: [],
        ingredientRows: 1,
        methodRows: 1
      });
      const popupOpen = ref(false);
     
  
      
      
      
      return {
        newRecipe,
       
      
        popupOpen,
        
      }
    }
  }
  </script>
  
  <style>
  
  h2{
    font-size:1rem;
  }
 
  .home {
    margin-left:13rem;
    flex-direction: column;
    align-items: center;
    display:grid;
  }

  a {
    color: #000000;
    text-decoration: none;
  }
  
  #naslovna{
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin:10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  #recept {
    appearance: none;
    border: none;
    background: none;
    cursor:pointer;
  
    background-color: #e5decf;
    font-size: 1.125rem;
    color: #ffffff;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
  
  h1 {
    font-size: 3rem;
    margin-bottom: 32px;
  }
  
  .recipes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.25rem;
  margin-left: -10rem;
  }
  
  .recipes .card {
    display: grid;
    padding: 7rem;
    border-radius: 5px;
    margin: 2rem;
    background-color:  #ffffff;
    
  }
  
  .recipes .card h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .recipes .card p {
    font-size: 1.125rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  
  
  
  </style>