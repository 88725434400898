<template>
		<aside :class="`${is_expanded ? '' : 'is-expanded'}`">
		<div class="logo">
			<img :src="logoURL" alt="Vue" /> 
		</div>

		<div class="menu-toggle-wrap">
			<button  class="menu-toggle" @click="ToggleMenu">
				<span class="material-icons">clear_all</span>
			</button>
		</div>

		<h3>Govori Brani^evo</h3>
		<div class="menu">
			<router-link to="/" class="button">
				<span class="material-icons">home</span>
				<span class="text">Po~etna</span>
			</router-link>
			<router-link to="/about" class="button">
				<span class="material-icons">description</span>
				<span class="text">Tekstovi</span>
			</router-link>
            
			<router-link to="/grbovi" class="button">
				<span class="material-icons">
                <img class="material-icon" src="@/assets/ljiljan.png" alt="Icon"/>
                </span>
				<span class="text">Heraldika</span>
			</router-link>
			
		</div>

			
			

		<div class="flex"></div>
		
	</aside>

</template>


<script setup>
import { ref } from 'vue'
import logoURL from '../assets/grb.png'

const is_expanded = ref(localStorage.getItem("is_expanded") === "false")
is_expanded.value = !is_expanded.value

const ToggleMenu = () => {

	is_expanded.value = !is_expanded.value

	if(!is_expanded.value){
	setTimeout(() => {
		is_expanded.value = true;
        }, 7000); 
	}
}  
    
</script>

<style lang="scss" scoped>

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

aside {
	display: flex;
	flex-direction: column;


	background-image: url("../assets/ancient.jpg");
	color: var(--light);

	width: calc(2rem + 32px);
    overflow:visible;
	min-height: 100vh;
	padding: 1rem;
    
	transition: 0.2s ease-in-out;

	.flex {
		flex: 1 1 0%;
	}

	.logo {
		margin-bottom: 1rem;

		img {
			width: 5rem;
			margin-left:-22px;
		}
	}

	.menu-toggle-wrap {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 1rem;

		position: relative;
		top: 0;
		transition: 0.2s ease-in-out;

		.menu-toggle {
			transition: 0.2s ease-in-out;
			.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-out;
			}
			
			&:hover {
				.material-icons {
					color: var(--primary);
					transform: translateX(0.5rem);
				}
			}
		}
	}

	h3, .button .text {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}

	h3 {
		font-size: 1.5rem;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
		color:rgb(255, 255, 255);
	}

	.menu {
		margin: 0 -1rem;

		.button {
			display: flex;
			align-items: center;
			text-decoration: none;

			transition: 0.2s ease-in-out;
			padding: 0.5rem 1rem;

			.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-in-out;
			}
			.text {
				color: var(--light);
				transition: 0.2s ease-in-out;
			}

			&:hover {
				background-color: var(--dark-alt);

				.material-icons, .text {
					color: var(--primary);
				}
			}

			&.router-link-exact-active {
				background-color: var(--dark-alt);
				border-right: 5px solid var(--primary);

				.material-icons, .text {
					color: var(--primary);
				}
			}
		}
	}

	.footer {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		p {
			font-size: 0.875rem;
			color: var(--grey);
		}
	}

	&.is-expanded {
		width: var(--sidebar-width);

		.menu-toggle-wrap {
			top: -3rem;
			
			.menu-toggle {
				transform: rotate(-180deg);
			}
		}

		h3, .button .text {
			opacity: 1;
		}

		.button {
			.material-icons {
				margin-right: 1rem;
			}
		}

		.footer {
			opacity: 0;
		}
	}

	@media (max-width: 1024px) {
		position: absolute;
		z-index: 99;
	}
}
</style>


