import { createStore } from 'vuex'

export default createStore({
  state: {
  
    recipes: [
      {
        slug: 'a',
        title: 'Kako je govorio prvi austrijski konzul o srbima i knezu Milo{u',
        image: require('@/assets/austrijski_konzul.jpg'),showImage:true,
        imagea: require('@/assets/milos_obrenovic.jpg'),showImagea:true,
        txt:'QQIDEMOO',

        ingredients: [
          'Presvije li gospodaru moj, da Vam iskreno po na{em ka`em: ja sam Hrvat i radujem se i oholim se time, [to sam postavqen za prvog carskog konzula u sredini toga hrabroga naroda i u onu slavnu zemqu, koje je krv, jezik i slava meni srodna i kod jedinitog na{ega knjaza ilirskog plemena Antun Mihanovi} prvi konzul Austrijske carevine u Beogradu Antun Mihanovi} (rođen 10. juna 1796. u Zagrebu,   14. novembar 1861. u Novim Dvorima kod Klawca) je bio hrvatski pesnik i  austrijski diplomata.'
          +'Napisao je stihove hrvatske himne.Od 1836. do 1838. bio je prvi austrijski konzul u Beogradu.',
        ],
        method: [
          'slika Antun Mihanovi} (rođen 10. juna 1796. u Zagrebu,  &#9840; 14. novembar 1861. u Novim Dvorima kod Klawca).',
          'slika srpski knez Milos Obrenovi}.',
        ]
      },



      {
        slug: 'b',
        title: 'O srbima kao mo}nom narodu "Istorija Osmanskog Carstva francuski Vikont A. De La Jonkvijer!',
        image: require('@/assets/srbiMocanNarod.jpg'),
        ingredients: [
          '"Od svih balkanskih naroda, najva`niji i najmoc}niji su bili Srbi, pred kojima je, izgleda, bila velika sudbina; ali ova hrabra, poetska, nemarna, neozbiqna rasa nikada nije poku{ala da asimiluje ostatke'
          +' anti~ke kulture, i navukla je na sebe mr`nju katoli~kog Zapada i kaznu izolacije"'
          +'vikont A. De LA JONKUIERE u "Istoriji Osmanskog carstva',
        ],
        imagea: require('@/assets/srbiMocanNarod.jpg'),showImage:true,
        txt:'Orginalni tekst:'
          +'"Of all the Balkan peoples, the most important and the most powerful were the Serbs they seemed to have a great destiny before them ; but this brave, poetic, careless, frivolous race never attempted to assimilate the remains of ancient culture, and incurred the hatred of the Catholic West and the penalty of isolation"'
          +' vikont A. De LA JONQUIERE, Histoire de l"Empire Ottoman"'
      }, 




      {
        slug: 'c',
        title: 'Srbi Tra~ani',
        image: require('@/assets/tracani2.jpg'),showImage:true,
        imagea: require('@/assets/tracani1.jpg'),showImagea:true,
        ingredients: [
          'U ^ASOPISU KOJI JE PRETVOREN U KWIGU POD NAZIVOM"CENTRALNI CASOPIS ZA ANTROPOLOGIJU,ETNOLOGIJU I PRAISTORIJU" IZ 1896 GODINE CITIRA SE AUSTRIJSKI ANTROPOLOG KARL PENKA KOJI JE NAPISAO SLEDE]E NA STRANICI 307 i 308:'+
          '"Za Tra~ane se mora pretpostaviti da su predhelensko-arijevsko stanovni{tvo Gr~ke, {to je uglavnom vidqivo iz razmatrawa mikenskih kulturnih ostataka i iz lingvisti~ko-arheolo{kih dokaza da ne samo isto~ni deo Balkanskog poluostrva, već i Gr~ka. , Mala Azija i isto~ni i severni delovi Ma|arske imali su tra~ke starosedeoce."',
        ], 
        
        method: [
          'slika skup antropolo{kih ~asopisa srocenih u kwigu "Centralni ~asopis za antropologiju,etnologiju i praistoriju" iz 1896 godine {tampanog u Vroclavu tada Austro-Ugarska!',
          'slika francuska istorijska karta iz 1848 godine koja pokazuje Srbiju ispod reci Srbija levo nalazi se rec TERRE RACY(Ra{ka zemlja) iz koje zapadnjaci izvode rec THRACE to jest Tracka zemqa!',
        ],
        a:'Originalni nemacki tekst https://archive.org/details/centralblattfr243bresuoft/page/n25/mode/2up ',
      }, 
      {
        slug: 'd',
        title: 'Ko je kriv za prvi svetski rat',
        image: require('@/assets/slika1.jpg'),showImage:true,
        imagea: require('@/assets/slika2.jpg'),showImagea:true,
        imageb: require('@/assets/slika3.jpg'),showImageb:true,
        ingredients: [
          'ISTORIJSKA ISTINA IZLAZI NA VIDELO!PRVI PUT U SRBIJI!'+
          'OVO JE DOKAZ KO JE KRIV ZA I SVETSKI RAT!DOKAZ DOLAZI SA NAJVI[EG MESTA,IZ USTA NIKOG DRUGOG,DO TADASWEG PREMIJERA ITALIJANSKE KRAQEVSKE VLADE DJOVANIA DJOLITIA!'+
          'Evo sta je rekao:'+
           '"Austrija je planirala rat protiv Servije u avgustu 1913. godine."'+
           'Eks-premijer Giolitti izjavljuje da je Be~ tada otkrio svoje namere Italiji.'+
          'Ovo nije bez osnova jer je Austro-Ugarske pripreme za rat platio glavom srpski patrijarh Lukijan Bogdanovi}!'+
          'ON JE PRVA ZRTVA RATA A NE FRANC FERDINAND!I RAT JE ZA SRBE POCEO WEGOVIM UBISTVOM!'+
          'Patrijarh Lukijan Bogdanovi} je 1. septembra 1913. godine u austrijskom gradu Bad Ga{tajnu pod nerazja{njenim okolnostima nestao.Wegovo nago telo prona|eno je u reci Akse. Odse~ena glava patrijarha Bogdanovi{a nikada nije prona|ena.Prema izve{tajima tada{nje srpske {tampe u Austrougarskoj postoji sumwa da je poglavar Srpske pravoslavne crkve pao kao `rtva politike ma|arskog premijera grofa I{tvana Tise.,',
        ],
        txt:'Orginalni tekst:'+
          'Giovanni Giolitti, the former Italian premier says Austria Planned War On Servia in August, 1913. Ex-Premier Giolitti Declares Vienna Revealed Its Intentions to Italy Then.” Giovanni Giolit…',
        method: [
          'slika italijanski kraqevski premijer Djovani Djoliti',
          'slika wegova izjava u anglo-saksonskim novinama u orginalu!',
          'slika srpski patrijarh Lukijan Bogdanovi} kome je odse~ena glava.',
        ]
      },     
      {
        slug: 'e',
        title: 'SARBAZ E SORBI I CRVENA BOJA!',
        image: require('@/assets/slika13.jpg'),showImage:true,
        imagea: require('@/assets/slika14.jpg'),showImagea:true,
        imageb: require('@/assets/slika15.jpg'),showImageb:true,
        ingredients: [
          'Na farsiju, jeziku Persije, „sarbaz“ znači „vojnik“, dok je „sarbaz-e sorbi“ glavni vojnik. Re~ „sorb“ na ovom jeziku (kao i na latinskom) znači „crveno“. Kao {to smo videli, za Skite je ova boja ozna~avala jug. A u kastinskom sistemu Mahabharate, to se odnosi na kastu ratnika.'+
          'U farsi jeziku ima dosta slicnih re~i kao u srpskom i taj jezik je ro|ak sanskritu!'+
          'Interesantno je da u srpskoj narodnoj noswi dominira CRVENA BOJA!Inace Ilirska Srbija je Crvena Srbija!'+
          'Najupe~atqivji detaq srpske noswe pored sajkace je `enska PREKA^A,PLISIRKA,BRANI^EVKA ILI POZAREVQANKA,sukwa koju nose `ene i devojke!'+
          'Interesantno je da ~uveni KlI^EVACKI IDOL ima istu preka~u koju nose danas `ene u Brani~evu(gde je i selo Kli~evac)i ona je CRVENE BOJE!Kli~eva~ki idol predstavqa figuru paganskog bo`anstva iz bronzanog doba, izra|enu od crne gline, prona|enu u selu Kli~evac pored Po`arevca, 1881. godine.Kli~ev~ki idol je pagansko bo`anstvo iz bronzanog doba, i svakako predstavqa `ensko bo`anstvo. Veličinom i kvalitetom izrade prevazilazi ve~inu ostalih figura i pru`a zanimqive podatke o verovawu ljudi bronzanog doba.Kli`eva`ki idol pripada periodu bronzanog doba kada je u Podunavwu i Banatu `ivela posebna kulturna grupa koju, po glavnim nalazi{tima iz Dubovca i `utog brda kod sela Usija, nazivamo dubova~ko-`utobrdska kultura. Obuhvata period od 1500.--1000. p. n. e. i to, najverovatnije u drugoj polovini navedenog razdobqa.,',
        ],
        method: [
          'slika Kli~ev~aki idol (na|en u selu Kli~evac/Po`arevaci)koji nosi sukwu ~ije {are odgovaraju sukwi `enske srpske narodne no{we!',
          'slika preka~a-sukwa na kojoj dominira crvena boja!',
          'slika devojka u po`arevackoj ili zagubickoj noswi koja nosi preka~u,plisirku ili pozarev`anku sa crvenim tonovima.',
        ]   
      },


      {
        slug: 'f',
        title: 'Sta kaze o kraqevini Jugoslaviji engleska kwizevnica Roseta Forbst',
        image: require('@/assets/slika4.jpg'),showImage:true,
        imagea: require('@/assets/slika5.jpg'),showImagea:true,
        imageb: require('@/assets/slika6.jpg'),showImageb:true,
        ingredients: [
          'EVO STA JE REKLA ^UVENA ENGLESKA KNJIZEVNICA I PUTOPISAC'+
          'ROSETA FORBST(KOJA JE POSETILA KRAQEVINU JUGOSLAVIJU IZME|U DVA RATA)O SRBIMA I O OSTALIMA(BIVSOJ BRA]I)!DA LI JE POGODILA U DLAKU?DA LI SE NESTO PROMENILO ZAKQUCITE SAMI?'+
          '“Srbi su pravi frajeri, “Amerikanci Balkana” koji gaze i mlate ostale ali donekle opravdano, jer ve}ina Jugoslovena su ipak te{ki primitivci kojima treba dr`ati ~izmu na vratu. Što se njih tiče, većina stanovnika regije su zapravo prikrivreni Srbi. Veliki su ratnici, uvek su bili i uvek }e ostati militantna “rasa”, u Prvom svetskom ratu doslovno su se golim rukama progurali kroz Makedoniju i Kosovo.'+
          'Beograd je ranije bio jednostavan ruralni grad ali sada uverqivo raste, usluga u restoranima je nenormalno brza, kao da su transplantovani iz Amerike. No dok je jednom iz Be~a trebalo samo 10 dana da na upit stigne odgovor, u Beogradu ~ekanje traje 3 meseca, tamo balkanska korupcija cveta u javnoj upravi gde nerad prikriva nezwe”.'+
          '“Slovenci i Hrvati su vidno pod uticajem Austrije. Zagreb i Qubqana su veseli i uređeni sredwoevropski gradovi s vrhunskom ponudom i oni tu ve}u razvijenost pomalo bahato nabijaju Beogradu na nos, kojeg smatraju orijentalnim i varvarskim. Hrvati su u{li u uniju s “inferiornim” Balkancima “ni`eg standarda” misle}i da će voditi glavnu re~ ali su se gadno prevarili. Srbima se u Hrvatskoj dodequju kqu~ni polo`aji dok se Hrvatske zvani~nike tera u Bosnu, koja vrvi “primtivnim Muslimanima.” Ne-srpskim zvani~nicima fali efikasniji Austrijski re`im gde su intelektualci lak{e napredovali”'+
          '“Makedonci su zbog jezi~nih specifi~nosti zapravo Bugari a ne Srbi. Bosanski Muslimani su primitivci Turkskog porekla, lako se pokoravaju bilo kojem autoritetu, pla}aju ve}e poreze ali “to je voqa Alahova.” Sela su im prepoznatqiva po odsutnosti kovr~ave balkanske svinje”.'+
          '“Crnogorci su siroma{ni ali je Crna Gora kao zapad. Kad bi se uporedila s Bosnom i Makedonijom, gde su tursko-orijentalni uticaji vidqivi na svakom koraku, Crna Gora je vi{e rezervisana/umerena. Crnogorci su `ilava i vredna “rasa,” nepokorni su i zameravaju politi~arima koji su ih uveli u Jugoslaviju. Viskoki su i zgodni, uvek su bili ratni~ka “rasa” i sad u brdima vode gerilski rat. Srbi ih ugwetavaju ali se dve rase me|usobno po{tuju i stravstveno vole svoje otaxbine”',
        ]
      },
      
      
      {
        slug: 'z',
        title: 'Stradawe srba u Beloj Srbiji',
        image: require('@/assets/slika11.jpg'),showImage:true,
        imagea: require('@/assets/slika8.jpg'),showImagea:true,
        imageb: require('@/assets/slika9.jpg'),showImageb:true,
        imagec: require('@/assets/slika10.jpg'),showImagec:true,
        imaged: require('@/assets/slika7.jpg'),showImaged:true,
        imagee: require('@/assets/slika12.jpg'),showImagee:true,
        ingredients: [
          'EKSLUZIVNO!POPTRESNO SVEDO^ANSTVO KAKO JE SRBIMA OTIMANA DEDOVINA I ZEMQA!'+
          'DOKAZ KAKO JE UNI[TENA BELA SRBIJA I SRBI U WOJ!NAIME POKRSTAVAWE BELIH SRBA U BALTI^KOJ SRBIJI(DANASWA NEMA^KA) BILO JE WIHOVO BRISAWE I UNISTEWE!OVA KWIGA POTRESNO JE SVEDO^ANSTVO O WIHOVOM PROGONU I OTIMAWU ZEMLJE SRPSKIM PLEMENIMA ,KAO STO SU BODRICI(ABODRITI)INA^E BRANI^EVCI(ABODRITI PREDENESCENTI) SU WIHOVO POD-PLEME..JA PRIPADAM TOM DREVNOM PLEMENU!I OVO JE POTRESNI DOKAZ KAKO SU MOJI PRECI I SAPLEMENICI UNISTAVANI OD STRANE GERMANA I HRIS^ANSKE CRKVE JER SU ODBIJALI DA OSTAVE STARU VERU I BOGOVE!'+
          'Ovde je nema~ki tekst u orginalu iz kwige koja se satoji iz dva dela,'+
          'kwiga se bavi poreklom plemi}ke kuce Fersen ili Versen koji su kao germanski kolonisti naselili otete Srpske Bodricke zemqe I Belu (balti~ku Srbiju)Kwiga je {tampana iz dva dela.I to 1884 godine a I 1909 godine.Tekst sa nema~kog sam li~no preveo!Ovo je kwiga o doseqavanju germanskih kolonista iz Dowe Saksonije,Hesena I Vestfalije ,jedne doseqene germanske plemicke porodice Versen ili Fersen koji su se naselili na otetoj srpskoj zemqi!Bilo je I obi~nih doseqenika iz Holandije i Skandinavije!Kao sto se vidi od 1170 do 1185 godine trebalo im je 15 godina da uni{te Srbe na obalama Balti~kog mora prisliqavaju}i ih da napuste staru veru I nasilno se hristijanizuju!Mewajuci svoj verski karakter Srbi gube I svoj teritorijalni I etnicki karakter kao I zemqe koje su naseqavali,koji ovim nasilnim genocidnim aktom postaju germanske!Srbi(Wendi)'+
          'beze u Pomeraniju(Pomorje) jer je ona jako dugo zadrzala srpski'+
          'karakter.Inace vladari Srbi Kasimir I I Bogislav I vladari Pomeranije poti~u od slavnog Bodri}kog kraqa Nikole Nakowica koga su ubili'+
          'Saksonci Germani zovu dinastija NAKONIDA!Danas u Pomorju kao ostaci tih nedoklanih I uni{tenih Srba su narod Ka{uba koji govori jezikom koji svaki SRBIN moze da razume!Kao I Bodri}i tj Brani~evci I Kasubi imaju veli~anstvenog crnog grifona na zutom stitu kao grb!Poqaci isto vode poreklo od Belih Srba'+
          'Srpski prevod:'+
          'Pomeranija(Pomorje) i Veliki Raden (Macklenburg)bili su'+
          'u to vreme samo Vendima(Srbima)naseqeni i naro~ito je vendsko(srpsko) plemstvo odbijalo da se pokrsti.Da bi slomio wihov prkos ,odlucio je 1170 godine grof Guncelin von Sverin,da svakog Venda(Srbina) koji se na|e u wegovoj grofoviji,da ga bez pardona obesi.'+
          'U to vreme odprilike 1185 godine vojvode Kazimr I i Bogislav prvi'+
          'od Pomorja(Pomeranije),unuci prvog kr{tenog vojvode(ina~e oni su bili SRBI)'+
          'da svaki Vend (Srbin) koji ne pre|e na hris}ansku veru,ima da'+
          'izgubi svoju li~nu imovinu i ne sme da obavqa nikakvu javnu funkciju!'+
          'Zbog toga ipak velika ve}ina Venda (Srba) bezi u Pomorje(Pomeraniju).Grof Guncelin von Sverin(Schverin) poku{ao je da sprovede i sprovodio je te zakone Regrutuju Nemce (posebno iz Donje Saksonije, Hesena I Vestfalija)Ti germanski kolonisti su dobili imovinu koja je prethodno bila oteta Vendima (Srbima), tako su nastali vrlo brzo veliki plemi}ki posedi i celi gradovi.',
        ],
        txt:'Nemacki tekst iz knjige:'
           +'Pommern und Macklenburg waren damals nur durch'
           +'Wenden bevölkert(Slavi genannt-Serben)und besonders'
           +'dem wendischen Adel widerstrebte es,sich taufen zu'
           +'lassen.Um deren Trotz zu brechen,bestimmte um 1170'
           +'Graf Gunzelin von Schwerin,dass jeder Wende der sich'
           +'in der Grafschaft Schwerin vorfände,ohne Pardon aufgehängt'
           +'werden sollte.Etwa um die gleiche Zeit(1185)verordneten'
           +'die Herzöge Casimir I. und Bogislaw I.von POmmern,die Enkel'
           +'des ersten getauften Herzogs,dass jeder Wende(Serbe),der'
           +'sich nicht zum Christentum bekenne,seinen Grundbesitz'
           +'verlieren sollte und dass keine Wende(Serbe)ein Amt bekleiden dürfe.'
           +'Die Wenden wanderten dann grösstenteils nach Pommerellen aus.'
           +'Um jene Gesetze durchzuführen,versuchte Gunzelin von Schwerin'
           +'Deutsche anzuwerben(insbesondere aus Niedersachsen,Hessen und'
           +'Westfalen)Die Eingewanderten erhielten den Besitz,der zuvor'
           +'den Wenden(Serben)abgenommen wurde.So entstanden sehr bald'
           +'grössere Güter und ganze Ortschaften.'
           +'Orginalni naziv knjige:'
           +'Geschichte des geschlechts von Versen und von Fersen Band 1'
           +'Als Manuskript gedruckt von Friedrich von Versen,Stettin:'
           +'Herrcke und Lebeling,1909'
           +'Geschichte des geschlechts von Versen und von Fersen Band 2'
           +'Urkundenbuch mit mehrere Kunst-undanderen Beilagen,sowie'
           +'einem Anhang:Als Manuskript gedruckt von Friedrich von Versen,'
           +'Berlin:Trowitzsch und Sohn,1885.',
        method: [
          'slika grb plemi}ke porodice VERSEN koja je kolonizovala oteta srpska imawa I zemqu.',
          'slika je grof Guncelin von Sverin koji je vrsio teror nad Srbima paganima',
          'slika Vojvoda srpski Kasimir I od Pomorja',
          'slika Vojvoda Bogislav I ili Boguslav I od Pomorja(Pomeranije)na ovomaversu pise LIVTIC,ako se zna da se u latinskom V cita kao U onda je QUTIC.U Beloj Srbiji je postojalo pleme QUTICA',
          'slika izgled korica kqig',
          'slika grb Kasubije',
          
        ]
      },
      
      {
        slug: 'bb',
        title: 'O Konstantinu Dejanovi}u',
        image: require('@/assets/slika19.jpg'),showImage:true,
        imagea: require('@/assets/slika20.jpg'),showImagea:true,
        imageb: require('@/assets/slika21.jpg'),showImageb:true,
        ingredients: [
          'U SVOM DELU "RE^NIK RIMSKE I GRCKE BIOGRAFIJE I MITOLOGIJE" VILIJAMA SMITA IZ 1872 GODINE,[TAMPANOG U LONDONU,NA STRANI 87,DAT JE RODOSLOV ISTOCNO ROMEJSKE CARSKE DINASTIJE PALEOLOG!GDE ENGLEZI PRIZNAJU DA JE DRAGAS BIO GOSPODAR MAKEDONIJE!'+
          'U rodoslovu kod cara Manojla II stoji da je o`enjen Irinom to jest Jelenom Dragas }erkom Kostantina Dragasa od Makedonije(CONSTANTINUS DRAGASES OF MACEDONIA)'+
          'ZNA^I ENGLEZI PRIZNAJU DA JE DRAGAS BIO GOSPODAR MAKEDONIJE!'+
          'U rodoslovu je i cerka Jelena udata za Lazara Brankovi}a,kao i Zoja ili Sofija udata za ruskog velikog kneza, koja je bila baba cara Ivana IV Vasiljevi}a Groznog.'+
          'Autor:'+
          'Ser Vilijam Smit (20. maj 1813 — 7. oktobar 1893) je bio engleski leksikograf. Postao je poznat po svom napretku u nastavi gr~kog i latinskog jezika u {kolama.'+
          'Smit je ro|en u Enfildu 1813. od roditeqqa nekonformista. Poha|ao je Madras House školu Xona Alena u Hakniju. Prvobitno predodre|en za teolo{ku karijeru, umesto toga je postao ~lan advokata. U me|uvremenu, sam je u~io klasiku u slobodno vreme, a kada je upisao Univerzitetski kolex London je odneo i gr~ku i latinsku nagradu. U{ao je u Grejs In 1830, ali je napustio studije prava radi mesta na Univerzitetskom kolexu i po~eo da pi{e o klasi~nim temama.'+
          'Smit je zatim skrenuo pa`nju na leksikografiju. Njegov prvi poku{aj bio je Re~nik gr~kih i rimskih starina, koji se pojavio 1842. godine, a najve}i deo je napisao on. Zatim je usledio Re~nik gr~ke i rimske biografije i mitologije 1849. Paralelni Re~nik gr~ke i rimske geografije pojavio se 1857, sa nekim vodećim nau~nicima tog vremena koji su bili povezani sa zadatkom.'+
          'Godine 1867. Smit je postao urednik Kuarterli Reviev-a, mesto koje je obavqao do svoje smrti.'+
          'Smit je progla{en za doktor gra|anskog prava od strane Univerziteta u Oksfordu i Univerziteta u Dablinu. Zvawe viteza mu je dodeqeno 1892. Umro je 7. oktobra 1893. u Londonu, i sahraqen je u porodi~noj grobnici na isto~noj strani grobqa Hajgejt.'
        ],
        txt:'Literatura:'+
            '"Dictionary of Greek and Roman biography and mythology"- "Recnik rimske i grcke biografije i mitologije" 1872 godine ,London,Engleska.',
        method: [
          'slika rodoslov carske kuce Paleolog.',
          'slika naslovna strana kqive Vilijama Smita "Dictionary of Greek and Roman biography and mythology"- "Re~nik rimske i gr~ke biografije i mitologije" 1872 godine ,London,Engleska.',
          'slika Ser Vilijam Smit (20. maj 1813 — 7. oktobar 1893) je bio engleski leksikograf.'
        ]   
      },
      {
        slug: 'gg',
        title: 'Kopernik o planeti zemqi',
        image: require('@/assets/slika16.jpg'),showImage:true,
        imagea: require('@/assets/slika17.jpg'),showImagea:true,
        imageb: require('@/assets/slika18.jpg'),showImageb:true,
        ingredients: [
          'EKSKLUZIVNO!'+
          'PLANETA ZEMQA SLOVENSKO IME SERBNISA!'+
          'POQSKI IZVORI O PLANETI ZEMQI I O TOME [TA JE PISAO KOPERNIK!U DELU "O OBRTAQU NEBESKIH KRUGOVA"SE SPOMIQU RUSI I SRBI!'+
          'A SAMA PLANETA ZEMQA SE NAZIVA SERBNISA!'+
          'RIMSKI PAPA JE ZABRANIO KOPERNIKOVO DELO 1616 GODINE I STAVIO GA U REGISTAR ZABRAQENIH KQIGA ,TAKOZVANI INDEX LIBRORUM PROHIBITORUM!'+
          'ZABRANA JE TRAJALA DO NEVEROVATNE 1822 GODINE!'+
          'DA LI SAD SHVATATE ZASTO SU SRBI NAJSTARIJA NACIJA TO JEST NAROD NA SVETU!'+
          'OVO JE NAPISAO JEDAN POQAK,A POQACI I RUSI KROZ ISTORIJU NISU IMALI BAS DOBRE ODNOSE!'+
          'Srpski prevod:'+
          'SERBNISA - slovensko ime ove planete.'+
          'Do kraja postojaqa slovenske sile, Lehistana, wegovi stanovnici su koristili ta~an naziv planete na kojoj su `iveli – SERBNISA – SABI I RUSI NISA. Sva imena nebeskih tela u slovenskom su `enskog roda, na primer, tre}i od Sunca / Solunic - Venisa / Venuska, zatim ~etvrti Serbnisa / Ziemia i peti Marusia / Mars. Prema zapisima Serbnisa, ima jedan satelit - Ksiaznica / Mesec.'+
          'Re~ Nisa, kao i Sabi i Rusija, pripada slovenskim primitivnim re~ima, odnosno niko nije u stawu da uka`e na mesto wenog porekla, mo`e se re}i da su re~i u ovoj grupi starije od Slovena. Iz letopisa znamo odakle dolazi re~ Rusija, odnosno od Nise, koju je ovde dao PERUN kao ime za dete svoje sestre Marije - RUSIJA. Me|utim, dat je u ~ast ve} postoje}e prole}ne vojske SVETOVIDA(VIDOVDAN), sastavqene od hrabrih SABINJANA(SRBINJANA) i nazvane RUSIJA. Ovaj zapis nam direktno govori da je ime RUSIJA i SABI starije, bo`anskog i vanzemaqskog porekla !!!'+
          'U na{im slovenskim zapisima ove re~i se javljaju kod predaka Svaroga i Svetovida koji su do{li na zemqu. Prema zapisima mnogih slovenskih knjiga, Svarog je za potomstvo podigao dva grada Nise, nastaniv{i istovremeno jedan od wih, a nalaze se u [leskoj i Moravskoj u Srbiji(GRAD NI[ JE NI[A). Ni{a je takođe `ensko ime, koje je nosila, na primer, Perunova `ena – NI[A. Dakle, ime na{e planete mo`emo shvatiti kao tri `enska imena, a ime kao planetu tri velike `enske SLAVE - SABI, RUSI I NISI - SIRBNISIA. I danas `ivi Srbi ovo ime vezuju za svoju mati~nu domovinu.'+
          'U 16. veku, ~uveni astronom Nikola Kopernik je, koriste}i glagoljske izvore, na slovenskom jeziku zapisao ~uveno le~i~ko remek-delo – „ABOUT ARCHNE ROTATION NEBESKIE SVARG / O ROTATION OF BLUE SVER“. Ta~no je opisao polo`aj „SERBNIS-a“ i njegovo kru`no kretawe unutar vrte}eg nebeskog SVARCH / ARCH SVARGA U KRETANJU ~ime je zapo~ela Kopernikova revolucija. Na astronomskom satu u Ol{tinu koji je napravio Kopernik i dalje se može videti njegov slovenski tekst i sam Nikola u wegovim sun~anim nao~arima.'+
          'U mnogim drugim slovenskim tekstovima, shvatajuc}i njegovu notaciju, jedna re~ NISA je ~esto referenca na ime ove Planete, a ne na grad ili reku. Sama re~ u fleksiji je ~lan mnogih imena ili prezimena, npr. KOPER-NIKA - NIKA / NISZA / NICA = NISA. Ime planete nije izuzetak jer su sva geografska imena, nazivi slovenskih zemaqa i rodova imena predaka, eventualno sa dodatkom reči POLE, GORA i MORE, npr. Princ Kija - POLSKIJE / OPOLSKIJE.',
        ],
        txt:'Poljski Orginalni tekst:'+
            'Poljski orginalni tekst:'+
            'SERBNYSA - słowiańska nazwa tej planety.'+
            'Do końca istnienia słowiańskiego mocarstwa Lechistan jego mieszkańcy używali dokładnej nazwy planety, na której mieszkali - SERBNYSA - SABI I RUSI NYSA. Wszystkie nazwy ciał niebieskich po słowiańsku są rodzaju żeńskiego np. trzecia od Słońca/Solunic - Wenysa/Wenuska, potem czwarta Serbnysa/Ziemia i piąta Marousia/Mars. Według zapisów Serbnysa posiada jednego satelitę - Książnica/Księżyc.'+
            'Słowo Nysa tak jak Sabi i Rusia należy do słowiańskich słów pierwotnych, to znaczy nikt nie jest w stanie wskazać miejsca jego pochodzenia, można powiedzieć że słowa w tej grupie są starsze od Słowian. Z kronik wiemy skąd pochodzi słowo Rusia, mianowicie z Nysy, nadane tu przez PERUNA jako imię dla dziecka swojej siostry Marii - RUSIA. Jednak nadane ku czci istniejącej już jarej armii SWITOWITA, złożonej z walecznych SABINEK i nazywającej się RUSIA. Zapis ten mówi nam wprost iż nazwa RUSIA i SABI jest starsza, pochodzenia boskiego, pozaziemskiego!!!'+
            'W naszych słowiańskich zapisach słowa te pojawiają się z przybyłymi na ziemię praojcami Swarogiem i Switowitem. Wg zapisów wielu słowiańskich ksiąg Swaróg wzniósł dla potomnych dwa miasta Nysa, zamieszkując jednocześnie jedną z nich, a znajdują się one na Śląsku i na Morawach w Serbii. Nysa to także imię żeńskie, które nosiła np. żona Peruna - NYSA. Więc nazwę naszej planety możemy rozumieć jako trzy imienia żeńskie, a nazwę jako planetę trzech wielkich żeńskich SŁAW - SABI, RUSI I NYSY - SIRBNYSIA. Żyjący jeszcze dzisiaj Serbowie kojarzą tą nazwę z ich rodzimą macierzą.'+
            'W 16 wieku sławny astronom MIKOŁAJ KOPERNIK korzystając z głagolicowych źródeł spisał po słowiańsku słynne Lechickie arcydzieło - "O OBROTACH ARCHNE NEBESNYCH SWARG / O OBROTACH NIEBIESKICH SWER". Dokładnie opisał położenie "SERBNYSY" oraz jej ruchu kolistym wewnątrz kołowrotowej niebiańskiej SWARCHY / SWARGI ARCHU W RUCHU co zapoczątkowało REWOLUCJĘ KOPERNIKOWSKĄ. Na zegarze astronomicznym w Olsztynie wykonanym przez Kopernika wciąż widać jego słowiański tekst oraz samego Mikołaja w okularach słonecznych.'+
            'W wielu innych słowiańskich tekstach rozumiejąc jego zapis, pojedyncze słowo NYSA często jest odniesieniem do nazwy tej Planety, a nie miasta czy rzeki. Samo słowo w odmianie jest członem wielu nazw czy nazwisk np. KOPER-NIKA - NIKA/NISZA/NICA = NYSA. Nazwa planety nie stanowi wcale żadnego wyjątku bo wszystkie nazwy geograficzne, nazwy krain i klanów słowiańskich to imiona przodków, z ewentualnie dodaniem słowa POLE, GÓRA i MORZE np. POLSKIE - PÓL Z KIJA - pól Opola należących do księcia Kija - POLSKIJE/OPOLSKIJE.',
        method: [
          'slika delo Nikole Kopernika "O obrtawu nebeskih krugova" koje je rimski papa zabranio 1616 godine i bilo je sve do 1822 ZABRAWENO!Verovatno sto se spomiwe starost Srba i Rusa!',
          'slika astronom Nikola Kopernik.',
          'slika ikona Ognjene Marije koju SRBI i danas strasno postuju predstavqena sa paganskim Perunovim cvetovima,Marija je sestra Boga Peruna ~ijoj je cerki Perun dao ime Rusija!Latinski naziv sa Rasku je RASCIA a Rusi i danas kazu za Rusiju RASIJA!',         
        ]
      }, 
      {
        slug: 'aac',
        title: 'Azbuka pre ]irila i metodija..ka`u bili smo nepisemni odakle ovo iz 1 veka',
        image: require('@/assets/slika23.jpg'),showImage:true,
        imagea: require('@/assets/slika22.jpg'),showImagea:true,
        imageb: require('@/assets/slika24.jpg'),showImageb:true,
        ingredients: [
          'OVO SE NE OBJAVLJUJE NA SVA ZVONA!SRBICA VINCANICA'+
          'EVO DOKAZA DA SU U VIMINACIUMU `IVELI SRBI I DA SU PISALI ]IRILICOM!ODAKLE SAD SRBI AKO SU SE DOSELILI U 6 VEKU!? KAD ARHEOLOG KORAC IZADJE SA NEKIM SENZACIONALNIM OTKRICEM STO SE TI^E GOTA RIMLJANA ILI SVIH DRUGIH UDARA SE NA SVA ZVONA;A KAD SU SRBI U PITAWU I DOKAZI O NJIMA ONDA SE CUTI!GDE CE MASON KORAC TO DA OBJAVI!'+
          'OVO JE NAJSTARIJI PISANI TEKST SA^UVAN NA SRPSKOM 1 VEK!OVO JE DOKAZ DA CIRILO I MEDODIJE NEMAJU NIKAKVE VEZE SA AZBUKOM NI SA OPISMENJIVANJEM "SLOVENA" POSTO SE VIDI DA SMO BILI PISMENI!'+
          'Viminacijum je arheolo{oko nalazi{te u blizini Starog Kostolca,12 km od Po`arevca...1.v ne'+
          'Prevod:'+
          'Petko Vidu{a Nikolic'+
          'ZAPIS NA OLOVNOM SVITKU'+
          'Pronalaza~: `eli da ostane anoniman.'+
          'Mjesto nalaska: Srbija, okolina Viminaciuma.'+
          'Veličina plo~e: nepoznata...'+
          'Vrijeme nastanka zapisa: prvi vijekovi nove ere.'+
          'Jezik zapisa: kelto-slavjanski.'+
          'Pismo zapisa: slavjanske rune.'+
          'Plo~a je napisana u ~ast izginulih ratnika kao posmrtna besjeda pri obredu spaqivanja izginulih:'+
          'Prevod:'+
          'SILNI OGAW SLUZBA ^ASNA'+
          'U SLAVU POMEN NARODNIH GLAVARA ^UVENIH'+
          ' I SLAVNIH I BLISTAVIH I SR^ANIH I SJAJNIH'+
          ' BORACA HEROJSKIH SKUPOCJENIH'+
          ' RO|AKA BROJA STRADALOG'+
          ' POKRECE ZEQE SVETOSNE'+
          ' SMISAO UZVI[ENI GROMOVITO MRIJETI'+
          ' Petko Vidusa Nikoli}',
        ],
        txt:'Orgininali tekst:НNH ΛЕСХОД YHC ÆГ'+
            'ΛЕXHД APEBь ЧΛОВЈЕЧАХ ГЛАВАRAEI OCTIΛОМ'+
            'H OYШHX ZPБΛOYШНТН І CPZBHXÆ П(Е)РΛ'+
            'Æ NEHДHBÆ ЧΛОВYЧRAENHX KOCTH O'+
            'THNHXO ЧHCΛJA ПHTHNH(E)ШHX •Г'+
            'JETH ДENÆHNO ШРÆNÆHДHB'+
            'ЖÆТ TRETÆРÆ ПJETHBH MРEAT',
      }, {
        slug: 'q',
        title: 'O srpskom plemenu Veti}i.',
        image: require('@/assets/slika25.jpg'),showImage:true,
        imagea: require('@/assets/slika26.jpg'),showImagea:true,
        imageb: require('@/assets/slika27.jpg'),showImageb:true,
        ingredients: [
          'EKSKLUZIVNO PRVI PUT U SRBIJI OBJAVQUJEM OVO!'+
          'SRPSKO IME PLEMENA VETI]I DOKAZ DA JE IME SLOVEN MLA|EG DATUMA I DA SVI SLOVENI POTICU OD SRBA!'+
          'FINSKO IME VENAJA IZVEDENO OD VETI]I,VENAJA ZNACI RUSIJA!'+
          'DOKAZ DA JE IZ SRPSKOG PLEMENA VETI]I IZVEDENO IME ZA "SLOVENSKO"PLEME ANTA KAO IME VENEDA TO JEST WENDA!'+
          'IME WEND U NEMA^KOM ZNACI SRBIN!'+
          'NESTO MALO IZ ISTORISKIH IZVORA O VETI]IMA:'+
          'Veti}i dugo nisu imali knezove, ali je dru{tvenu strukturu karakterisala demokratija i samouprava.Kao i razna druga slovenska plemena, Vetići su gradili kurgane na teritoriji koja sada pripada savremenoj ruskoj dr`avi.Primarna hronika iz 12. veka bele`i da su Vetići , Radimi~i i Severjani „imali iste obi~aje“, da su svi `iveli nasilnim životom, „spaljivali svoje mrtve i ~uvali pepeo u urnama postavqenim na stubove pored puteva“, i da nisu ulazili u monogamne brakova, ali su umesto toga praktikovali poligamiju, posebno poliginiju.'+
          'ZAR SRBI NISU POLITICKI TAKAV MENTALNI SLON DA NE POSTUJU AUTORITET I DA JE SLOBODA VRHUNSKI IDEAL?DRUGO SRPSKO PLEME SEVERJANI ZIVELO JE I NA DUNAVU U DONJOJ MEZIJI ,TAKOZVANIH 7 SEVERJANSKIH PLEMENA!Interesantno je da se da se teritorija 7 Severjanskih plemena poklapa sa teritorijom gde su ziveli Dunavski Tribali(Tribali istrios,Istar (grč. Ἴστρος), Dunav).'+
          'Prva hronika navodi izvesnog plemenskog vo|u Vjatka(setite se ime VRATKO NEMAQIC ili JUG BOGDAN otac knegiqe Milice,ime njegovo verovatno potice od ovog kneza Vjatka)kao praoca plemena, koji je bio lja{ki brat Radima od koga su nastali Radim~i( rus. Radimi~i). Veti}i ( rus. Vjati~i) su se uglavnom bavili zemljoradwom i sto~arstvom. Između 9. i 10. veka, Veti}i (rus.Vjati~i) su plaćali danak Hazarima, a kasnije i kijevskim knezovima.Pleme je, me|utim, neprestano poku{avalo da odbrani sopstvenu politi~ku nezavisnost sve do ranog 12. veka. Do 11. veka Veti}i (rus. Vjati~i)su vec} naselili basen Moskve i podru~je današnje Moskve. U 11. i 12. veku pleme je osnovalo niz gradova zbog razvoja zanatstva i povećanja trgovine, uklju~uju}i Moskvu, Koltesk, Dedoslav, Nerinsk i druge. U drugoj polovini 12. veka zemqa Veti}i (rus.Vjati~i)je bila podeqqena među knezovima Suzdalja i ^ernigova. Poslednja direktna referenca na Veti}i (rus. Vjati~e) je napravqena u hronici iz 1197. godine. Indirektne reference, me|utim, mogu se pratiti do ranog 14. veka.'+
          'Sveti Kuk{a Kijevskope~erski je bio misionar koji je mnoge Veti}e (rus.Vjati~e) preobratio u hri~}anstvo (1115. godine), nakon {to su mu qihovi poglavari odsekli glavu 27. avgusta oko. 1115.'+
          'U Moskvi postoje brojni arheolo{ki spomenici koji govore istori~arima o Veti}ima(rus. Vjati~ima). Qihova utvr|ena naseqa iz 11. veka nalazila su se u istorijskom centru dana{nje Moskve, i to na Borovi~kom brdu, Kolomenskom (mesto nekada{njeg sela |akovskoe), Kuncevu (oblast Moskve) i dr. Tragovi Vetićkih(rus.Vjatičkih)naselja mogu se naći i u Bratejevu, Zjuzinu, Aljo{kinu, Matvejevskom i drugim lokalitetima Moskve. Du` gorweg toka Oke i Dona prona|ene su humke sa kremiranim telima.'
        ],
        txt:'OVO SE NALAZI U KNJIZI "Uloga migracija u istoriji evroazijske stepe: sedelačka civilizacija naspram "varvara i nomada. -"The Role of Migration in the History of the Eurasian Steppe: Sedentary Civilization vs. "Barbarian" and Nomad." pisac Andrew Bell-Fialkoff objavljenoj 2000 godine na strani 137 sledece pise:'+
            '"Neki lingvisti izvode "ANTI"-"Antes" od "Viatichi" (VETIĆI). Ako jeste, ime'+
            'mogla biti varijanta „Veneda”. Činjenica da finski "Venaja" označava "Rusiju" i "Vend" je stari germanski termin za Slovena, kao i drugi termin'+
            'za Lužičke Srbe, daje podršku ovoj teoriji. Još jedna indikacija je "Želja",'+
            'koji se nalaze u delima nekih arapskih i persijskih istoričara i geografa. Najzad, koren se javlja i u staroslovenskim vestima'+
            '(venšti) (veći), poljski vi(cej (više), i razna muška imena poput'+
            'Venceslav, Vjačeslav i sl."',
        method: [
          'slika knjiga "Uloga migracija u istoriji evroazijske stepe: sedelačka civilizacija naspram "varvara" i nomada." objavljenoj 2000 godine.',
          'slika tekst na 137 strani u gornjoj prvoj recenici koji spominje SRPSKO PLEME VETIĆI od kojih je nastalo ime ANT i ime WEND sto na nemackom znaci SRBIN!',
          'slika pleme VETIĆI (rus.Vjatiči)odaju počast knezu Svjatoslavu I Igoreviču (966).Minijatura iz Radziviłła Letopisa, kraj 15. veka.',
        ]
      },{
        slug: 'bbb',
        title: 'Panta Obrenovi}',
        image: require('@/assets/slika28.jpg'),showImage:true,
        ingredients: [
          '"MI OBRENOVI]I,NE BISMO STVORILI JUGOSLAVIJU!"'+
          '"За моју фамилију, Југославија је силом створена творевина од стране Карађорђевића, који нису успоставили дијалог са другим јужнословенским народима. Обреновићи су, насупрот, тежили, остварењу великог српског јединства, дипломатијом, попут Немањића. Карађорђевићи су изабрали радикалније решење, узимање власти и наметање своје воље силом. А потом је и Тито наставио одржавање власти силом."'+
          'rekao je Panta „Panka“ Obrenovi}'+
          'Panta „Panka“ Obrenovi} (Pariz, Francuska, oko 1945 ? – Pariz, Francuska, 2002), bio je sin Stefana Obrenovi}a, konji~kog kapetana francuske vojske u Francuskoj i wegove supruge, i unuk Đorđa Obrenovića (1889–1925), vanbra~nog sina srpskog kralja Milana M. Obrenovi}a (1854–1901) i Grkinje Artemize Joanides Hristi}, i wegove supruge.Bio je sin Stefana Obrenovi}a, konji~kog kapetana francuske vojske u Francuskoj i njegove supruge, i unuk |orđa Obrenovi}a (1889–1925), vanbra~nog sina srpskog kralja Milana M. Obrenovi}a (1854–1901) i Grkinje Artemize Joanides Hristi}, i njegove supruge.[kolovao se u Evropi i SAD.Studirao je primenjenu umetnost i vi{u {kolu za komunikacije, a istovremeno je, kao specijalni vaspita~, pratio predavala za osnivawe {kole - ateqea za hendikepiranu decu.Tako|e, u Francuskoj i [vajcarskoj je zavr{io više {kole za socijalno - kulturnog animatora.`iveo je i radio je uglavnom u Parizu, u Francuskoj.'+
          'Bio je osniva~ i do`ivotni predsednik „Fonda Obrenović“ u Parizu i predsednik nekih humanitarnih udru`enja za pomo} mentalno bolesnoj deci.U osnivačkim aktima „Fonda Obrenovi}“, umesto Panta pi{e Panka.Sve akte „Fonda Obrenovi}“ potpisivao je }irilicom.'+
          'Preminuo je 2002. godine u Parizu, kao posledwi član nekada{nje srpske vladarske porodice Teodorovi} – Obrenovi}',
        ],
        method: [
          'slika Panta Obrenovi}',
        ]   
      }
    ]
  },
  mutations: {
    ADD_RECIPE (state, recipe) {
      state.recipes.push(recipe)
    }
  }
})